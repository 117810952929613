<template>
    <div class="service-steps">
        <div class="row">
            <div class="col-md-7">
                <Form class="joinUs-form" @submit="onSubmit" :validation-schema="currentSchema">

                    <swiper class="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :space-between="20"
                        :allowTouchMove="false" :navigation="{
                    enabled: true,
                    nextEl: '.myNext',
                    prevEl: '.myPrev',
                }" :pagination="{ type: 'progressbar', }">
                        <div class="index-steps">
                            <div :class="[index > 0 ? 'active' : '']" class="step">1</div>
                            <div :class="[index > 1 ? 'active' : '']" class="step">2</div>
                            <div :class="[index > 2 ? 'active' : '']" class="step">3</div>
                        </div>
                        <swiper-slide>
                            <div class="slider-title">
                                {{
                    this.$i18n.locale == 'ar' ?
                        `الرجاء اضافة معلوماتك كشريك لإتمام `
                        :
                        `Please add your information as a partner of Etmam`
                }}
                            </div>

                            <div class=" row item p-4">
                                <div class="col-md-6 mt-3">
                                    <Field as="select" @keydown="handleKeydown" name="partnership_type_id">
                                        <option value="" disabled hidden>
                                           {{ this.$i18n.locale == 'ar' ? 'اختر نوع الشراكة' : 'Choose the type of partnership' }}
                                        </option>
                                        <option :value="item.id" v-for="item in partnershipsTypes">
                                            {{item.name}}
                                        </option>
                                    </Field>
                                    <ErrorMessage name="partnership_type_id" class="joinUs-form-error" />

                                </div>
                                <div class="col-md-6 mt-3">
                                    <Field @keydown="handleKeydown" :placeholder="$t('Registration_number')" name="cr_number" type="number" />
                                    <ErrorMessage name="cr_number" class="joinUs-form-error" />
                                </div>
                                <div class="col-md-6 mt-3">

                                    <Field @keydown="handleKeydown" :placeholder="$t('Company_name')" type="text" name="company" />
                                    <ErrorMessage name="company" class="joinUs-form-error" />

                                </div>
                                <div class="col-md-6"></div>

                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="slider-title">

                                !
                                {{
                    this.$i18n.locale == 'ar' ?
                        ` اكمل الخطوات بملئ بياناتك الشخصية `
                        :
                        `Complete the steps by filling in your personal data!`

                }}
                            </div>

                            <div class=" row item p-4">
                                <div class="col-md-6 mt-3">

                                    <Field @keydown="handleKeydown" :placeholder="$t('Full_name')" type="text" name="name" />
                                    <ErrorMessage name="name" class="joinUs-form-error" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <input @keydown="handleKeydown" id="phone" type="tel" name="mobile_number">
                                    <span id="output" class="joinUs-form-error mt-1 mb-1">
                                        {{ $i18n.locale == 'ar' ?
                    "الرجاء ادخال رقم صحيح" : 'Please enter a valid number below'
                                        }}
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <Field @keydown="handleKeydown" name="city" v-slot="{ field }">
                                        <multiselect :searchable="false" v-model="value2" @select="PickId2" v-bind="field"
                                            :options="cities"
                                            :placeholder="this.$i18n.locale == 'ar' ? 'اختر المدينة' : 'Choose the city'"
                                            label="name" track-by="name" selectLabel=""
                                            :selectedLabel="this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'"
                                            :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'">
                                        </multiselect>
                                    </Field>
                                    <ErrorMessage name="city" class="joinUs-form-error" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <Field @keydown="handleKeydown" :placeholder="$t('Email')" type="email" name="email" />
                                    <ErrorMessage name="email" class="joinUs-form-error" />
                                </div>



                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="slider-title mb-4">
                                {{
                    this.$i18n.locale == 'ar' ?
                        ` اضف ملاحظاتك و الوقت المناسب لك `
                        :
                        `Add your comments and the time that suits you`

                }}
                            </div>


                            <div class=" row item p-4">
                                <div class="col-md-12 mt-4">

                                    <Field @keydown="handleKeydown" name="notes" :placeholder="$t('Notes')" as="textarea" class=" p-4"
                                        style="height: 115px;"> </Field>
                                    <ErrorMessage name="notes" class="joinUs-form-error" />

                                </div>


                                <div class="col-md-6 mt-3">
                               {{   this.$i18n.locale == 'ar' ?
                        `اختر الوقت المناسب للتواصل `
                        :
                        `Choose the right time to communicate`}}
                                    <Field @keydown="handleKeydown" name="suitable_time_to_contact" as="select">

                                        <option value="الفترة الصباحية من 8 ص إلى 12">{{ $t('morning') }}</option>
                                        <option value="الفترة المسائية من 4 م إلى 11">{{ $t('Evening') }}</option>
                                    </Field>
                                    <ErrorMessage name="suitable_time_to_contact" class="joinUs-form-error" />
                                </div>
                                <div class="col-md-6 mt-2"></div>
                            </div>
                        </swiper-slide>
                    </swiper>


                    <div class=" navigation-button send-button">

                        <div class="d-flex  navigation-button "
                            :class="[index == 0 ? ' justify-content-flex-end' : 'justify-content-between']">
                            <button @click.prevent="handlePrevClick" class="next-step myPrev"
                                :class="[index == 0 ? 'd-none' : '']">
                                {{
                    this.$i18n.locale == 'ar' ?
                        `الخطوة السابقة`
                        :
                        `Previous`
                }}
                            </button>
                            <button class="next-step" type="submit" :class="[index == 2 ? 'd-none' : '']">
                                <span>
                                    {{
                    this.$i18n.locale == 'ar' ?
                        `الخطوة التالية`
                        :
                        `Next`
                }}
                                </span>
                            </button>

                            <button type="submit" id="but-submit" class="next-step "
                                :class="[index != 2 ? 'd-none' : '']">
                                <span>
                                    {{
                                    this.$i18n.locale == 'ar' ?
                                    `إرسال الطلب`
                                    :
                                    `Send`
                                    }}
                                </span>
                            </button>
                        </div>
                    </div>


                </Form>

            </div>
            <a
                :href="frame_url"
                :style="`background: linear-gradient(180deg, rgba(34, 84, 118, 0.00) 0%, #225476 100%), url(${frame_join_us_photo}) lightgray 50% / cover no-repeat;`"
                class="p-0 col-md-5 mt-6 d-flex justify-content-center align-items-center joinus-img">
                <div class="info">
                    <p>{{ $t('Start your journey in the business world with confidence and let us be part of your success') }}</p>
                    <button class="btn-seconde">{{ $t('Free consultation') }}</button>
                </div>
            </a>
        </div>
    </div>

</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
import * as yup from 'yup';
import { configure } from "vee-validate";
import intlTelInput from "../../intl-tel-input.js"
import Cookie from 'cookie-universal'
import { defineRule } from 'vee-validate';
import Multiselect from 'vue-multiselect';
const cookie = Cookie()
export default {

    data() {
        return {
            index: 0,
            swiper: null,
            partnershipsTypes: [],
            cities: [],
            iti: null,
            swiper: null,
            FullNumber: '',
            Status: false,
            value2: '',
            city_Id: '',
        }
    },
    props: {
        frame_join_us_photo: {
            type: String
        },
        frame_url: {
            type: String
        }
    },

    setup() {
        defineRule('number', (value, [lang]) => {
            if (value === undefined || value === null || value === '') {
                return true; // Allow empty values
            }

            if (typeof value !== 'number') {
                return lang === 'ar' ? 'يجب إدخال رقم' : 'must enter a number';
            }

            return true;
        });
        configure({
            validateOnInput: true,
        });
        const schema = [
            yup.object({
                partnership_type_id: yup.string().required(cookie.get('lang') == 'ar' ? 'اختيار نوع الشراكة مطلوب' : 'partnership type required'),
                cr_number: yup.mixed().test(
                    'number',
                    (field) => {
                        const lang = cookie.get('lang');
                        return lang === 'ar' ? 'يجب إدخال رقم' : 'must enter a number';
                    },
                    (value) => typeof value === 'number'
                ).optional(),
                company: yup.string().optional(),


            }),
            yup.object({
                name: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل الاسم مطلوب' : 'name required'),
                email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الإلكتروني مطلوب' : 'email required').email(cookie.get('lang') === 'ar' ? 'يجب إدخال بريد إلكتروني صالح' : 'email must be valid'),
                city: yup.object().required(cookie.get('lang') == 'ar' ? 'اختيار المدينة مطلوب' : 'City required'),
            }),
            yup.object({
                suitable_time_to_contact: yup.string().required(cookie.get('lang') == 'ar' ? 'اختيار الوقت المناسب للتواصل مطلوب' : 'time to contact required'),
                notes: yup.string().optional(),
            })
        ]
        return {
            schema
        }

    },
    computed: {
        currentSchema() {
            return this.schema[this.index];
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        Multiselect
    },
    mounted() {
        this.$store.dispatch('partnershipsType').then(r => {
            this.partnershipsTypes = this.$store.state.partnershipsTypes;

        });
        this.$store.dispatch('getCities').then(r => {
            this.cities = this.$store.state.cities;
        });

        const output = document.querySelector("#output");
        const inputElement = document.querySelector('#phone');

        const iti = intlTelInput(inputElement, {
            i18n: {
                searchPlaceholder: this.$i18n.locale == 'ar' ? 'بحث' : 'Search',
            },
            initialCountry: "auto",
            nationalMode: true,
            // geoIpLookup: callback => {
            //     fetch("https://ipapi.co/json")
            //         .then(res => res.json())
            //         .then(data => callback(data.country_code))
            //         .catch(() => callback("us"));
            // },
            geoIpLookup: callback => {
        fetch("https://ipapi.co/country")
          .then(res => {
            if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    return res.text();
          })
          .then(data => callback(data))
          .catch(() => callback("us"));

      },
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js"
        });

        const handleChange = () => {
            let text;
            if (this.$i18n.locale == 'ar') {
                if (inputElement.value != '') {
                    text = iti.isValidNumber()
                        ? ""
                        : "رقم غير صحيح - الرجاء اعد المحاولة";
                    if (iti.isValidNumber()) {
                        this.FullNumber = iti.getNumber();
                    }
                } else {
                    text = "الرجاء ادخال رقم صحيح";
                }
            }
            else {
                if (inputElement.value != '') {
                    text = iti.isValidNumber()
                        ? ""
                        : "Invalid number - please try again";
                    if (iti.isValidNumber()) {
                        this.FullNumber = iti.getNumber();
                    }
                    else {
                        this.FullNumber = false;
                    }
                } else {
                    text = "Please enter a valid number below";
                }
            }
            const textNode = document.createTextNode(text);
            output.innerHTML = "";
            output.appendChild(textNode);
        };

        // listen to "keyup", but also "change" to update when the user selects a country
        inputElement.addEventListener('change', handleChange);
        inputElement.addEventListener('keyup', handleChange);
    },

    methods: {
        handleKeydown(event) {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent the default tab action
      }
    },

        PickId2() {
            this.city_Id = this.value2.id;
        },

        onSwiper(swiper) {
            this.swiper = swiper;
        },
        onSlideChange() {
            this.index = this.swiper.activeIndex;
        },
        handlePrevClick() {
            this.index = this.index - 1
        },

        async onSubmit(values) {
            if (this.index == 0) {
                this.index++;
                this.swiper.slideTo(this.index);
            }
            if (this.index == 1) {
                if (this.FullNumber) {
                    this.index++;
                    this.swiper.slideTo(this.index);
                }
            }
            if (this.index > 1) {
                if (values.suitable_time_to_contact != undefined) {
                    const formData = {
                        "partnership_type_id": values.partnership_type_id || "",
                        "cr_number": values.cr_number.toString() || "",
                        "company": values.company || "",
                        "name": values.name || "",
                        "email": values.email || "",
                        "city_id": this.city_Id || "",
                        "mobile_number": this.FullNumber || "",
                        "suitable_time_to_contact": values.suitable_time_to_contact || "",
                        "notes": values.notes || ""

                    }
                    // if (this.Status == true) {
                        document.getElementById('but-submit').setAttribute("disabled", "");
                        await
                            axios.post(`user/join-us-requests`, formData)
                                .then((res) => {
                                    if (res.status == 200) {
                                        notify({
                                            type: "success",
                                            title: this.$i18n.locale == 'ar' ? 'انضم إلينا' : 'Join Us',
                                            text: res.data.message,
                                        });
                                        // this.$store.commit("SET_JoinUsData", res.data.data);
                                    }

                                    this.$router.push("/")
                                })
                                .catch(function (error) {

                                    notify({
                                        type: "error",
                                        title: "خـطـأ !!",
                                        text: " حدث خطأ أثناء تسجيل طلبك",
                                    });
                                });
                    // }
                    // this.Status = true;
                }

            }


        }
    },
    // watch: {
    //     index(newindex, oldindex) {
    //         if (newindex < 2) {
    //             this.Status = false;
    //         }
    //     }

    // }

};
</script>
